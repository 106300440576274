:root {
  --bg-color: linear-gradient(180deg, #ffffff 0%, #d4dfef 100%);
  --bg-2: #FAFAFA;
  --bg-3: #ffffff;
  --bg-4: #F1F2FF;
  --txt-1: #424d60;
  --txt-2: #eef0f4;
  --txt-grad-1: linear-gradient(90deg, #b98fc8 0%, #26b3de 100%);
  --txt-grad-2: linear-gradient(13deg, #406aff 0%, #f7666f 100%);
  --txt-grad-3: linear-gradient(13deg, #000000 0%, #A4A4A4 100%);
  --btn-1: #5021FF;
  --btn-2: #f7666f;
  --btn-3: #d4dfef;
  --btn-4: #DCB3E3;
  --btn-5: #D1E1F0;
  --btn-6: #35B0D7;
}

#Events {
  padding: 5rem 4%;
}
.event_cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem .5rem;
  margin-top: 5rem;
}
.event_cont .item {
  width: 100%;
  height: 20rem;
  transition: .25s;
  border: .1rem solid var(--btn-6);
  border-radius: .8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  overflow: hidden;
}
.event_cont .item img {
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
  position: relative;
  transition: .5s;
  object-fit: cover;
  cursor: pointer;
}
.event_cont .item img:hover {
  transform: scale(1.02);
}
@media (max-width: 991px) {
  .event_cont {
    grid-template-columns: repeat(2,1fr);
  }
}
@media (max-width: 813px) {}
@media (max-width: 768px) {}
@media (max-width: 601px) {
  .event_cont {
    grid-template-columns: repeat(1,1fr);
  }
  #Products .event_cont .item {
    height: 30rem;
  }
}
@media (max-width: 520px) {}
@media (max-width: 412px) {}