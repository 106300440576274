/* product */
#product {
  padding-top: 7rem;
  padding-bottom: 8rem;
  background: var(--bg-2);
}
#product .cards-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
  min-height: max-content;
}
.prod-card {
  width: 100%;
  height: 100%;
  background: var(--bg-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem;
  border: .5px solid var(--btn-2);
  border-radius: .5rem;
  transition: .25s ease-in-out;
}
.prod-card:hover {
  margin-top: -.8rem;
}
.prod-card .img_cont {
  height: 13rem;
  width: 100%;
}
#product .cards-cont img {
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  border-radius: .3rem;
  object-fit: cover;
}
#product .cards-cont h3 {
  font-weight: 500;
}
#product .cards-cont p {
  font-size: 14px;
}
#product .cards-cont h3, 
#product .cards-cont p {
  text-align: center;
  margin-top: 1.5rem;
}
.rect {
  width: 100%;
  display: flex;
  background: var(--btn-3);
  height: 13rem;
  margin-top: 8rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  padding: 0 4%;
}
.rect .txts h2 {
  font-size: 2.3rem;
}
.rect .txts h3 {
  font-size: 2.3rem;
  background: var(--txt-grad-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.rect .btn {
  height: 5rem;
  width: 16rem;
  font-size: 1.4rem;
}
@media (max-width: 991px) {
  .prod-card .img_cont {
    height: 10rem;
  }
}
@media (max-width: 813px) {
  .rect .txts h2 {
    font-size: 1.8rem;
  }
  .rect .btn {
    font-size: 1.1rem;
    height: auto;
    width: max-content;
    padding: 1rem 1.4rem;
  }
}
@media (max-width: 768px) {
  #product .cards-cont {
    flex-direction: column;
    align-items: center;
  }
  #product .cards-cont .prod-card:hover  {
    margin: unset;
  }
  .rect {
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem;
    min-height: 15rem;
  }
  .rect .txts h2 {
    font-size: 1.4rem;
  }
  .rect .txts h3 {
    font-size: 1.7rem;
  }
  .subtexts h2 {
    font-size: 1.3rem;
  }
  .subtexts p {
    font-size: .9rem;
  }
  .prod-card .img_cont {
    height: 21rem;
  }
}
@media (max-width: 520px) {
  .prod-card .img_cont {
    height: 15rem;
  }
}