

:root {
  --bg-color: linear-gradient(180deg, #ffffff 0%, #d4dfef 100%);
  --bg-2: #FAFAFA;
  --bg-3: #ffffff;
  --bg-4: #F1F2FF;
  --txt-1: #424d60;
  --txt-2: #eef0f4;
  --txt-grad-1: linear-gradient(90deg, #b98fc8 0%, #26b3de 100%);
  --txt-grad-2: linear-gradient(13deg, #406aff 0%, #f7666f 100%);
  --txt-grad-3: linear-gradient(13deg, #000000 0%, #A4A4A4 100%);
  --btn-1: #5021FF;
  --btn-2: #f7666f;
  --btn-3: #d4dfef;
  --btn-4: #DCB3E3;
  --btn-5: #D1E1F0;
  --btn-6: #35B0D7;
}
/* Home */
#home {
  background: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
#home.active_home {
  overflow: hidden;
}
.home-left { /* it is in the right */
  width: 35%;
  height: max-content;
  padding-top: 4rem;
  z-index: 99;
  position: absolute;
  top: 20%;
  right: 2%;
  justify-self: flex-start;
}
.home-left h1 {
  font-size: 7.5rem;
  background: var(--txt-grad-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: -2rem;
}
.home-left h3 {
  font-size: 2.1rem;
  color: var(--txt-1);
  font-weight: 400;
}
.home-left h2 {
  position: relative;
  font-size: 3.3rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  animation: homeBgText 8s linear infinite;
  animation-delay: 2s;
  color: transparent;
  -webkit-text-stroke: .7px var(--btn-1);
  background-image: var(--txt-grad-2);
  background-repeat: no-repeat;
  background-position: -22rem 0;
}
.home-left h2::before {
  content: '';
  position: absolute;
  top: 10%;
  left: 0;
  width: 0;
  height: 70%;
  border-right: 2px solid var(--btn-1);
  z-index: -1;
  animation:   homeCursorText 8s linear infinite;
  animation-delay: 2s;
}

.home-left .text-animate {
  position: relative;
  width: fit-content;
  padding-left: .5rem;
  width: 22rem;
}
.home-left p span {
  color: var(--btn-1);
}
.home-left p {
  max-width: 33rem;
  margin-bottom: 2rem;
  color: var(--txt-1);
}
.social {
  display: flex;
  gap: 1.1rem;
  align-items: center;
  width: 25rem;
  height: 5rem;
  margin-top: 3rem;
}
.social a {
  padding: 1rem;
  border-radius: 50%;
  background: var(--txt-2);
  box-shadow: 4px 0px 14px rgba(0, 0, 0, .15);
  transition: .25s;
}
.social a:hover {
  margin-top: -.5rem;
}
.social i {
  font-size: 1.4rem;
  display: inherit;
}
.home-right {
  width: 60%;
}
/*KEYFRAMES ANIMATION*/

@keyframes homeBgText {
  0%,10%,100% {
    background-position: -21.5rem 0;
  }
  65%,85% {
    background-position: 0 0;
  }
}

@keyframes homeCursorText {
  0%,10%,100% {
    width: 0;
  }
  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }

  74%,
  81%{
    opacity: 0;
  }
}
@media (max-width: 991px) {
  .home-left h1 {
    font-size: 6.5rem;
  }
  .home-left h3 {
    font-size: 2.4rem;
  }
}
@media (max-width: 813px) {
  #home {
    flex-direction: column;
  }
  .home-right {
    width: 100%;
  }
  .home-left {
    width: 100%;
    display: block;
    position: unset;
  }
  .home-left h1 {
    font-size: 6rem;
  }
}
@media (max-width: 520px) {
  .home-left h1 {
    margin-bottom: .1rem;
  }
  .home-left h3 {
    font-size: 2rem;
  }
  .home-left p {
    max-width: 25rem;
  }
}