.pop_cont {
  position: fixed;
  top: 10%;
  left: -100%;
  width: 15rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 .8rem;
  background-color: #D5E6DE;
  border: 1px solid #ACCEBC;
  border-radius: .3rem;
  z-index: 200;
  transition: .25s;
}
.pop_cont.true {
  left: 1%;
}
.pop_cont.red {
  background-color: #F3D8DA;
  border-color: #E7B1B6;
}
.pop_cont.red i,
.pop_cont.red p {
  color: #d84a58;
}
.pop_cont i {
  font-size: 1.9rem;
  color: #29a15d;
}
.pop_cont p {
  font-size: 1.3rem;
}
