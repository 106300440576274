#about {
  border-bottom: .5px solid var(--btn-2);
}
#about .container {
  padding-top: 5rem;
  height: 100%;
}
#about .contents {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 5rem;
  justify-content: space-between;
  align-items: center;
}
#about .contents .content-left {
  width: 42%;
  min-width: 30rem;
  font-size: 1.1rem;
}
#about .contents .content-right {
  width: 55%;
  min-width: 40rem;
}
#about .contents .content-right img {
  width: 100%;
}
