:root {
  --bg-color: linear-gradient(180deg, #ffffff 0%, #d4dfef 100%);
  --bg-2: #FAFAFA;
  --bg-3: #ffffff;
  --bg-4: #F1F2FF;
  --txt-1: #424d60;
  --txt-2: #eef0f4;
  --txt-grad-1: linear-gradient(90deg, #b98fc8 0%, #26b3de 100%);
  --txt-grad-2: linear-gradient(13deg, #406aff 0%, #f7666f 100%);
  --txt-grad-3: linear-gradient(13deg, #000000 0%, #A4A4A4 100%);
  --btn-1: #5021FF;
  --btn-2: #f7666f;
  --btn-3: #d4dfef;
  --btn-4: #DCB3E3;
  --btn-5: #D1E1F0;
  --btn-6: #35B0D7;
}
.arrow {
  position: absolute;
  top: 50%;
  left: 16rem;
  transform: translate(-50%, -50%);
  transform: rotate(90deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  border-bottom: 3px solid var(--btn-1);
  border-right: 3px solid var(--btn-1);
  transform: rotate(90deg);
  margin: -15px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(225deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(225deg) translate(20px, 20px);
  }
}