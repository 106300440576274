/* footer */
#footer {
  min-height: unset;
  height: fit-content;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.footer-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-links {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}
.footer-links .link-cont {
  display: flex;
  width: 23%;
  flex-direction: column;
}
.link-cont a,
.link-cont p {
  font-size: .9rem;
}
.footer-links .link-cont:nth-child(n+2) p {
  margin: 1.7rem 0 2rem;
  min-height: 1.5rem;
  font-size: 1.1rem;
}
.footer-links .footer-1 {
  min-width: 33%;
  margin-right: 4%;
}
.footer-1 .logo {
  display: flex;
  width: max-content;
  align-items: flex-end;
  text-align: center;
  margin-bottom: 1.5rem;
}
.footer-1 .logo img{
  width: 3rem;
  margin-right: .5rem;
}
.footer-1 .logo p{
  font-size: 1.8rem;
}
.footer-cont .footer-4 .social {
  display: none;
}
.link-cont .social a {
  box-shadow: none;
  padding: .8rem;
}
.footer-copy {
  width: 122%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: .5px solid var(--btn-2);
}
.footer-copy p {
  width: 100%;
  padding: 0 2%;
  text-align: center;
}

@media (max-width: 813px) {
  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 1fr);
    gap: 0 25%;
  }
  .footer-links .link-cont{
    width: 100%;
    min-width: 16rem;
  }
  .footer-links .footer-2{
    margin-top: 2.5rem;
  }
}
@media (max-width: 601px) {
  .footer-links .footer-2{
    margin-top: 3.5rem;
  }
}
@media (max-width: 462px) {
  .footer-links .footer-2{
    margin-top: unset;
  }
  .footer-links {
    display: block;
  }
  .footer-links .link-cont{
    margin: 0;
  }
  .footer-links .link-cont:nth-child(n+2) p {
    margin: 1rem 0;
  }
  .footer-cont .footer-3 .social {
    display: none;
  }
  .footer-cont .footer-4 .social {
    display: flex;
  }
  .footer-4 p {
    display: none;
  }
}
