/* contact */
#contact {
  padding-bottom: 3rem;
  padding-top: 3rem;
  border-bottom: .5px solid var(--btn-2);
}
#contact .contents{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
#contact .contents .content-left {
  width: 50%;
  min-width: 33rem;
  position: relative;
  font-size: 1.1rem;
  margin-top: 2rem;
}
#contact .contents .content-left form .icon-cont {
  display: none;
}
#contact .contents .content-right {
  position: absolute;
  top: 0%;
  right: -100%;
  width: 90%;
}
#contact .contents .content-right img {
  width: 100%;
  margin-top: -2rem;
  max-width: 31rem;
}
form{
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  width: 100%;
}
form .input-1,
form .input-2{ 
  width: 100%;
  height: 3.4rem;
  display: flex;
  gap: 1.2rem;
  justify-content: space-between;
}
form input {
  flex: 1;
  min-width: 2rem;
  background: transparent;
  border: .1rem solid var(--btn-4);
  border-radius: .2rem;
  padding-left: 1rem;
  font-size: 1rem;
  color: var(--txt-1);
}
form textarea {
  width: 100%;
  height: 16rem;
  background: transparent;
  border: .1rem solid var(--btn-4);
  border-radius: .2rem;
  padding: 1rem;
  font-size: 1rem;
  color: var(--txt-1);
}
#form .btn {
  width: 14rem;
}
@media (max-width: 601px) {
  #contact .contents .content-left {
    width: 100%;
    min-width: unset;
  }
}
@media (max-width: 520px) {
  form .input-1,
  form .input-2 {
    flex-direction: column;
    height: 7.2rem;
  }
}



form #choosendiv {
  width: 4rem;
  height: 3rem;
  padding: .2rem;
  background: var(--btn-5);
  border-radius: 1px;
  display: flex;
}
form #choosendiv img {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  align-self: center;
  object-fit: contain;
}