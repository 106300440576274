header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: .5rem 4%;
  display: flex;
  background: #FFFFFF;
  z-index: 100;
  align-items: center;
  justify-content: space-between;
  color: var(--txt-1);
  height: 4rem;
}
#menu-icon {
  display: none;
}
#menu-icon i{
  font-size: 31px;
  color: var(--txt-1);
  cursor: pointer;
}
header .logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 10rem;
  margin-right: 1.5rem;
}
.logo img {
  width: 2.5rem;
}
.logo p {
  font-size: 2rem;
  font-family: "roboto";
  background: var(--txt-grad-3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  min-width: max-content;
}
nav {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
nav ul {
  display: flex;
  justify-content: space-between;
  width: 35rem;
  font-size: 16px;
  color: inherit;
}
nav ul a {
  color: var(--txt-1);
  transition: .25s;
}
nav ul a:hover {
  color: var(--btn-1);
}
#languageSelect {
  width: 5rem;
  height: 2rem;
  background: transparent;
  border: none;
  font-size: 15px;
  margin-left: 2rem;
}
#languageSelect:focus {
  outline: none;
  border-color: initial;
}
@media (max-width: 991px) {
  nav ul {
    width: 31rem;
  }
  .logo p {
    font-size: 2rem;
  }
}
@media (max-width: 813px) {
  /* Nav Menu Start */
  #menu-icon {
    display: block ;
  }
  nav {
    position: absolute;
    display: block;
    left: -100%;
    top: 0;
    width: 100%;
    margin-top: 4rem;
    padding: 2rem 4%;
    background: var(--btn-3);
    z-index: 100;
    transition: .25s ease;
    transition-delay: .25s;
  }
  nav.active {
    left: 0;
    transition-delay: 0s;
  }
  nav .active-nav {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--btn-1);
    border-top: .1rem solid rgba(0, 0, 0, .2);
    z-index: 100;
    transition: .25s ease;
    transition-delay: 0s;
  }
  nav.active .active-nav {
    left: 100%;
    transition-delay: .25s;
  }
  nav ul {
    display: block;
  }
  nav a {
    display: block;
    font-size: 1.1rem !important;
    margin: 1.5rem 0;
    transition: .25s ease;
    transition-delay: 0s;
  }
  #languageSelect{
    margin: 0;
    font-size: 1.1rem;
    width: 5rem;
  }
  nav ul a:last-child span {
    left: 3.4rem;
    top: -0.5rem;
  }
}