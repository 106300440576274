/* Customers */
#customers {
  background-image: url("../../img/bg/customers.png");
  background-position: top;
  background-size: cover;
  padding-bottom: 8rem;
  background-color: var(--bg-color);
}
#customers .cards-cont {
  display: flex;
  justify-content: center;
  height: 20rem;
  gap: 2%;
  margin-bottom: 2rem;
}
.cards-cont .ccart {
  width: 31%;
  min-width: 15rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--txt-1);
  padding: 0rem 1rem 1rem;
  border-radius: .5rem;
  background: var(--txt-2);
  transition: .25s ease-in-out;
  z-index: 99;
}
.cards-cont .ccart:hover {
  margin-top: -.5rem;
}
.cards-cont .ccart img {
  width: 20%;
  margin-bottom: 1.1rem;
}
.cards-cont .ccart h2 {
  font-weight: 500;
}
.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subtitle {
  margin-bottom: 3rem;
}
.subtitle h3 {
  font-size: 2rem;
  color: var(--txt-2);
}
.subtitle h2 {
  font-size: 2rem;
  background: var(--txt-grad-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.agent_cont {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-rows: repeat(2, 6rem);
  gap: 1rem;
}
.agent_cont .agent_1,
.agent_cont .agent_2 {
  width: 100%;
  height: 100%;
  position: relative;
}
.agent_cont .img_cont {
  width: max-content;
  height: 100%;
  padding: 1px;
  border: .1rem solid var(--btn-6);
  border-radius: .5rem;
}
.agent_cont .img_cont img {
  height: 100%;
  object-fit: contain;
  border-radius: .45rem;
}
.agent_cont div a {
  position: absolute;
  top: 21%;
  left: 22rem;
  font-size: 1.3rem;
  background: var(--txt-grad-2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 991px) {
  .cards-cont .ccart p {
    font-size: .9rem;
  }
}
@media (max-width: 813px) {

  #customers .cards-cont {
    align-items: center;
    flex-direction: column;
    height: max-content;
    gap: 1rem;
  }
  .cards-cont .ccart {
    width: 100%;
    padding: 1rem;
  }
  .cards-cont .ccart:hover {
    margin: unset;
  }
  .cards-cont .ccart .icon{
    width: 50%;
  }
}
@media (max-width: 768px) {
  .customer-container h3{
    font-size: 2rem;
  }
  #about .contents .content-left {
    font-size: 1rem;
    min-width: 100%;
  }
}
@media (max-width: 520px) {
  .arrow {
    left: 15rem;
  }
  .agent_cont div a {
    left: 20rem;
    top: 15%;
  }
}
@media (max-width: 480px) {
  .agent_cont div a {
    top: 20%;
    left: 19rem;
    font-size: 1rem;
  }
  .arrow {
    left: 14rem;
  }
}