/* Products Page */
#Products {
  padding-top: 5rem;
  margin-bottom: 7rem;
}
.Products-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Products-container .Prod_cart {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--bg-4);
  border-radius: .5rem;
  gap: 2%;
}
.Products-container .Prod_cart .img-cont {
  background: var(--btn-5);
  border-radius: .4rem;
  width: 30%;
  min-width: 20rem;
  display: flex;
  position: relative;
}
.yt_cont img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.Products-container .Prod_cart .img-cont img {
  width: 100%;
  align-self: center;
}
.Products-container .subcolor {
  color: var(--btn-6);
  font-weight: 500;
}
.yt_cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  width: max-content;
  height: max-content;
  width: 65px;
  cursor: pointer;
}


/* screens */

@media (max-width: 991px) {
  .Products-container .Prod_cart .img-cont {
    min-width: 15rem;
    padding: 0.5rem;
  }
  .Products-container .subtexts {
    padding: 1rem 2rem 1rem 0;
  }
  .Products-container .subtexts .btn-cont {
    gap: 3%;
  }
}
@media (max-width: 813px) {
  #Products {
    padding: 5rem 4% 0;
  }
}
@media (max-width: 768px) {
  .Products-container .btn {
    font-size: 1rem;
    padding: .5rem .8rem;
  }
}
@media (max-width: 601px) {
  #Products {
    padding: 5rem 2% 0;
  }
  .Products-container .Prod_cart {
    gap: 3%; 
  }
  .Products-container .Prod_cart .img-cont {
    min-width: 13rem;
  }
  .Products-container .btn {
    font-size: .8rem;
    padding: 0.5rem;
    min-width: 8rem;
  }
}
@media (max-width: 520px) {
  #Products {
    padding: 5rem 5% 0;
  }
  .Products-container .Prod_cart {
    flex-direction: column;
  }
  .Products-container .Prod_cart .img-cont {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .Products-container .subtexts {
    padding: 1rem 2rem;
  }
  .Products-container .subtexts h2 {
    text-align: center;
  }
}